import generateRender from 'shared/utils/generateRender';
import DefaultPreloader from './DefaultPreloader';
import HriPreloader from './HriPreloader';
import LadyluckPreloader from './LadyluckPreloader';

const AssetPreloader = generateRender({
  default: DefaultPreloader,
  hri: HriPreloader,
  ladyluck: LadyluckPreloader,
});

export default AssetPreloader;
