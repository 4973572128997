import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const MillelacsTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: '1.5%',
    },
    StarIcon: {
      ...defaultTheme.LimitedTimeOffer.StarIcon,
      width: '12%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      left: 'calc(50% - 85px)',
    },
    MainContainer: {
      ...defaultTheme.LimitedTimeOffer.MainContainer,
      position: 'relative',
      top: '0.5rem',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: 15,
    },
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      position: 'absolute',
      right: windowWidth < 1300 ? 'calc(50% - 200px)' : 'calc(50% - 205px)',
    },
    TopBar: {
      ...defaultTheme.NavigationBar.TopBar,
      height: '5.7rem',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: '#01294E',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background: 'linear-gradient(180deg, #FFD213 0%, #FF8500 100%)',
    },
    BarText: {
      ...defaultTheme.Bar.BarText,
      marginLeft: 'auto',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      top: -1,
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MileStoneRowLoyaltyIcon: {
      ...defaultTheme.DailySpin.MileStoneRowLoyaltyIcon,
      height: 28,
      width: 28,
    },
    RegDaysItemLoyaltyIcon: {
      ...defaultTheme.DailySpin.RegDaysItemLoyaltyIcon,
      height: 20,
      width: 20,
    },
  },
  VipAccessFlow: {
    ...defaultTheme.VipAccessFlow,
    VipAccessButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      fontWeight: 800,
    },
    VipAccessButtonImage: {
      ...defaultTheme.VipAccessFlow.VipAccessButtonText,
      width: 210,
    },
    VipAccessRedeemButtonText: {
      ...defaultTheme.VipAccessFlow.VipAccessRedeemButtonText,
      fontWeight: 800,
      color: theme.palette.common[42],
    },
  },
  OutOfCoin: {
    ...defaultTheme.OutOfCoin,
    QuickBuyOfferDetails: {
      ...defaultTheme.OutOfCoin.QuickBuyOfferDetails,
      bottom: windowHeight > 1200 ? '12.5%' : '15.5%',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#f5e9c0',
      stopColor2: '#d2ab2d',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#284e74',
      stopColor2: '#010306',
    },
  },
  SmartBanner: {
    ...defaultTheme.SmartBanner,
    LogoButtonStyle: {
      ...defaultTheme.SmartBanner.LogoButtonStyle,
      height: '150%',
      bottom: '30%',
      left: '-30%',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    BoxContainer: {
      ...defaultTheme.BonusLinkMessage.BoxContainer,
      top: '-10vh',
    },
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      bottom: '-3.7rem',
    },
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      bottom: '-2.5rem',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    PurchaseResponseHighlightHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHighlightHeader,
      fontWeight: '600',
    },
    PurchaseResponseHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHeader,
      fontWeight: '600',
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      fontSize: '1em',
    },
    ExclamationIcon: {
      ...defaultTheme.OfferDetail.ExclamationIcon,
      width: 126,
      height: 'auto',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    ScreenBackground: {
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderTitleContainer: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      margin: 4,
    },
    NeedHelpText: {
      ...defaultTheme.LinkAccountForm.NeedHelpText,
      top: '0.3rem',
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    LogoImage: {
      height: '31%',
    },
    VIPHourTile: {
      ...defaultTheme.VIPItem.VIPHourTile,
      marginTop: '1%',
    },
    ItemPlusOffer: {
      ...defaultTheme.VIPItem.ItemPlusOffer,
      paddingTop: '0.5rem',
    },
    LoyaltyPointsLogo: {
      ...defaultTheme.VIPItem.LoyaltyPointsLogo,
      height: '4rem',
      width: '4rem',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    HeaderImage: {
      ...defaultTheme.Settings.HeaderImage,
      height: '4.7rem',
    },
    BackArrowContainer: {
      ...defaultTheme.Settings.BackArrowContainer,
      top: '1.5rem',
    },
    BackgroundLightDivider: {
      ...defaultTheme.Settings.BackgroundLightDivider,
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
  },
  Wheel: {
    ...defaultTheme.Wheel,
    Amount: {
      ...defaultTheme.Wheel.Amount,
      color: theme.palette.common[1],
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  },
  ResetPassword: {
    ...defaultTheme.ResetPassword,
    FormInputsFields: {
      ...defaultTheme.ResetPassword.FormInputsFields,
      background: theme.palette.primary[32],
      border: 'none',
      borderRadius: '0.6em',
    },
    InputLabel: {
      ...defaultTheme.ResetPassword.InputLabel,
      color: theme.palette.common[1],
    },
  },
  SignUp: {
    ...defaultTheme.SignUp,
    FormInputTheme: {
      ...defaultTheme.SignUp.FormInputTheme,
      background: 'rgb(0 39 62)',
      borderRadius: '0.6em',
      border: 'none',
    },
    MultipleInputInRowWrapper: {
      ...defaultTheme.SignUp.MultipleInputInRowWrapper,
      width: '49%',
    },
    TermsLink: {
      ...defaultTheme.SignUp.TermsLink,
      color: '#92B3F6',
    },
    NeedHelpLink: {
      ...defaultTheme.SignUp.NeedHelpLink,
      color: '#B4A269',
    },
  },
  TermsModalWrapper: {
    ...defaultTheme.TermsModalWrapper,
    NeedHelpLink: {
      ...defaultTheme.TermsModalWrapper.NeedHelpLink,
      color: '#B4A269',
    },
  },
  GameUnavailable: {
    ...defaultTheme.GameUnavailable,
    ButtonText: {
      ...defaultTheme.GameUnavailable.ButtonText,
      paddingTop: '0px',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      bottom: -10,
    },
    WatchAndEarnIcon: {
      ...defaultTheme.BottomBar.WatchAndEarnIcon,
      height: '7.5em',
      marginBottom: '-3em',
    },
    MailIcon: {
      ...defaultTheme.BottomBar.MailIcon,
      height: '7.5em',
      marginBottom: '-3em',
    },
    SlotsIcon: {
      ...defaultTheme.BottomBar.SlotsIcon,
      height: '7.5em',
      marginBottom: '-3em',
    },
    VIPIcon: {
      ...defaultTheme.BottomBar.VIPIcon,
      height: '8.5em',
      marginBottom: '-3.1em',
    },
    RewardCenterIcon: {
      ...defaultTheme.BottomBar.SlotsIcon,
      height: '10em',
      marginBottom: '-3.8em',
    },
    MissionsIcon: {
      height: '7.5em',
      marginBottom: '-2.5em',
    },
    BarItemIcon: {
      ...defaultTheme.BottomBar.BarItemIcon,
      height: '7.5em',
      marginBottom: '-3em',
    },
    TimerLabel: {
      ...defaultTheme.BottomBar.TimerLabel,
      position: 'relative',
      bottom: 0,
      marginBottom: '-10%',
    },
    TimerWrapper: {
      ...defaultTheme.BottomBar.TimerWrapper,
      height: '68%',
    },
    TimerBackground: {
      ...defaultTheme.BottomBar.TimerBackground,
      height: '100%',
    },
  },
  StoreDownload: {
    ...defaultTheme.StoreDownload,
    Container: {
      ...defaultTheme.StoreDownload.Container,
      backgroundColor: 'black',
    },
  },
  RewardCenter: {
    ...defaultTheme.RewardCenter,
    RewardCenterMainContentHolder: {
      ...defaultTheme.RewardCenter.RewardCenterMainContentHolder,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    ActiveTabImage: {
      ...defaultTheme.RewardCenter.ActiveTabImage,
      margin: '1.5px 1em',
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  LobbyOverlay: {
    ...defaultTheme.LobbyOverlay,
    MainSceneContainer: {
      ...defaultTheme.LobbyOverlay.MainSceneContainer,
      paddingTop: '4.5rem',
    },
  },
  Start: {
    ...defaultTheme.Start,
    Logo: {
      ...defaultTheme.Start.Logo,
      marginTop: windowHeight > 700 ? '6%' : '0px',
    },
    MainContentContainer: {
      ...defaultTheme.Start.MainContentContainer,
      marginTop: windowHeight > 700 ? 30 : -20,
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 10,
      textShadow: '2px 2px 2px #000000',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinAmount,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '22px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '0.9vmin',
      marginTop: 0,
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: '2px',
        },
        InnerItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem,
          Stack: {
            ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem.Stack,
            height: '85%',
            marginBottom: 5,
            padding: '4px 0 4px 10%',
          },
          StackLoyaltyIcon: {
            ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackLoyaltyIcon,
            height: 42,
            width: 22,
          },
          StackAmount: {
            ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.InnerItem.StackAmount,
            paddingTop: 0,
          },
        },
      },
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    FillProgress: {
      ...defaultTheme.MissionsProgress.FillProgress,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillProgressWithoutCard: {
      ...defaultTheme.MissionsProgress.FillProgressWithoutCard,
      marginTop: '-8px',
    },
    FillEnd: {
      ...defaultTheme.MissionsProgress.FillEnd,
      marginTop: windowWidth < 1400 ? '-12px' : '-8px',
    },
    FillEndWithoutCard: {
      ...defaultTheme.MissionsProgress.FillEndWithoutCard,
      marginTop: '-8px',
    },
    ProgressAnimation: {
      ...defaultTheme.MissionsProgress.ProgressAnimation,
      bottom: windowWidth < 1400 ? '-165%' : '-85%',
    },
    ProgressAnimationWithoutCard: {
      ...defaultTheme.MissionsProgress.ProgressAnimationWithoutCard,
      bottom: '-85%',
    },
  },
  CurrencyDisplay: {
    ...defaultTheme.CurrencyDisplay,
    Icons: {
      ...defaultTheme.CurrencyDisplay.Icons,
      loyalty: {
        ...defaultTheme.CurrencyDisplay.Icons.loyalty,
        left: -25,
      },
      coins: {
        ...defaultTheme.CurrencyDisplay.Icons.coins,
        left: windowWidth > 1700 ? 'calc(50% - 100px)' : 'calc(50% - 90px)',
        height: 50,
        width: 50,
      },
    },
    CoinsLabel: {
      ...defaultTheme.CurrencyDisplay.CoinsLabel,
      paddingLeft: 15,
    },
  },
  ForgotPassword: {
    ...defaultTheme.ForgotPassword,
    ModalContainer: {
      ...defaultTheme.ForgotPassword.ModalContainer,
      height: '35vh',
      minHeight: '300px',
    },
    ModalBody: {
      ...defaultTheme.ForgotPassword.ModalBody,
      marginBottom: '2%',
      justifyContent: 'center',
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: '5rem',
    },
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      color: theme.palette.common[1],
    },
  },
  Login: {
    ...defaultTheme.Login,
    ModalContainer: {
      ...defaultTheme.Login.ModalContainer,
      height: '35vh',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      height: 'calc(100vh - 5rem - 5.7rem)', // 100vh - bottom bar height - top bar height
      border: '5px solid #0090ed',
      background: 'linear-gradient(to bottom, #004068 0%, #004A7A 25%, #005C9B 50%, #004A7A 75%, #004068 100%)',
    },
  },
  PurchaseFlow: {
    ...defaultTheme.PurchaseFlow,
    billingProfileSelect: {
      ...defaultTheme.PurchaseFlow.billingProfileSelect,
      background: 'rgba(100, 100, 100, 0.1)',
    },
    creditCardField: {
      ...defaultTheme.PurchaseFlow.creditCardField,
      background: 'rgba(100, 100, 100, 0.1)',
    },
  },
};

export default MillelacsTheme;

