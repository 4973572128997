import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';
import generateRender from 'shared/utils/generateRender';

const LoyaltyPointsLogo = styled.img`
  height: 5rem;
  width: 5rem;
  object-fit: contain;

  @media screen and (max-width: 1500px) {
    height: 3rem;
    width: 3rem;
    ${({ theme }) => theme}
  }
  ${({ theme }) => theme}
`;

const DefaultPriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const LadyluckPriceWrapper = styled(DefaultPriceWrapper)`
  position: relative;
  top: -1rem;
  @media screen and (max-height: 960px) {
    top: -2rem;
  }
`;

const FreeItemContainer = styled.div`
  position: relative;
  margin-top: 1vmin;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FreeItemWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DefaultButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 0.7rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1600px) {
    margin-bottom: 0rem;
  }
`;

const LadyluckButtonWrapper = styled(DefaultButtonWrapper)`
  @media screen and (max-height: 800px) {
    position: relative;
    top: -2rem;
  }
  @media screen and (min-height: 800px) and (max-height: 960px) {
    position: relative;
    top: -1.5rem;
  }
`;

const FreeTextWrapper = styled.div`
  font-size: 6vmin;
  font-weight: bold;
`;

const DefaultItemPlusOffer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24%;
  padding-top: 0px;
  width: 100%;
  @media screen and (max-width: 1550px) {
    height: 26%;
    padding-top: 1rem;
    ${({ theme }) => theme}
  }

  @media screen and (max-width: 1300px) and (min-height: 900px) {
    height: 30%;
  }

  ${({ theme }) => theme}
`;

const LadyluckItemPlusOffer = styled(DefaultItemPlusOffer)`
  padding-top: 0.5rem;
  position: relative;
  top: -3rem;
  @media screen and (max-height: 960px) {
    top: -4rem;
  }
`;

const DefaultVIPAccessWrapper = styled.div`
  font-size: 2.3vmin;
  @media screen and (max-width: 1550px) {
    font-size: 2vmin;
  }
`;

const LadyluckVIPAccessWrapper = styled(DefaultVIPAccessWrapper)`
  position: relative;
  top: -4.5rem;
  @media screen and (max-height: 800px) {
    top: -5rem;
  }
`;

const ItemPrice = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
`;

const PlusLoyaltyText = styled.div`
  font-size: 1.8rem;
  @media screen and (max-width: 1550px) {
    font-size: 1.3rem;
  }
  ${({ theme }) => theme}
`;

const Styles = {
  buyButtonImage: {
    width: '70%',
    height: '2.5rem',
    cursor: 'pointer',
  },
  buyButtonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    height: '4rem',
  },
  buyButtonText: {
    fontSize: '26px',
  },
  tileInnerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common[1],
  },
};

const defaultVIPPackTitle = css`
  padding-top: 44%;
  @media screen and (max-width: 1300px) and (min-height: 880px) {
    padding-top: 64%;
  }
`;

const VIPPackTitleWithIcon = css`
  padding-top: 10px;
`;

const packageTitleTextStyle = generateRender({
  default: defaultVIPPackTitle,
  hri: VIPPackTitleWithIcon,
  millelacs: VIPPackTitleWithIcon,
  jamul: VIPPackTitleWithIcon,
  ladyluck: VIPPackTitleWithIcon,
});

const DefaultDurationFriendlyFull = styled.div`
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 700;

  @media screen and (max-width: 1550px) {
    font-size: 1.7rem;
  }
  ${packageTitleTextStyle}
`;

const LadyluckDurationFriendlyFull = styled(DefaultDurationFriendlyFull)`
  position: relative;
  top: -4.5rem;
  font-size: 3rem;
  @media screen and (max-height: 800px) {
    font-size: 2.5rem;
    top: -5.2rem;
  }
`;

const ShopStyles = generateRender({
  default: {
    PriceWrapper: DefaultPriceWrapper,
    ButtonWrapper: DefaultButtonWrapper,
    ItemPlusOffer: DefaultItemPlusOffer,
    VIPAccessWrapper: DefaultVIPAccessWrapper,
    DurationFriendlyFull: DefaultDurationFriendlyFull,
  },
  ladyluck: {
    PriceWrapper: LadyluckPriceWrapper,
    ButtonWrapper: LadyluckButtonWrapper,
    ItemPlusOffer: LadyluckItemPlusOffer,
    VIPAccessWrapper: LadyluckVIPAccessWrapper,
    DurationFriendlyFull: LadyluckDurationFriendlyFull,
  },
});

const { PriceWrapper, ButtonWrapper, ItemPlusOffer, VIPAccessWrapper, DurationFriendlyFull } = ShopStyles;

export {
  LoyaltyPointsLogo,
  PriceWrapper,
  FreeItemContainer,
  FreeItemWrapper,
  ButtonWrapper,
  FreeTextWrapper,
  ItemPlusOffer,
  VIPAccessWrapper,
  ItemPrice,
  PlusLoyaltyText,
  Styles,
  DurationFriendlyFull,
};

