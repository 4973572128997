import DefaultLayout from './DefaultLayout';
import BoxyLayout from './BoxyLayout';
import generateRender from 'shared/utils/generateRender';

const FormInputs = generateRender({
  default: DefaultLayout,
  jamul: BoxyLayout,
  millelacs: BoxyLayout,
  ladyluck: BoxyLayout,
});

export default FormInputs;

