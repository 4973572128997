import React from 'react';
import ImageBW from '../../../components/ImageBoundsWrapper';
import { CheckBox, CheckBoxText } from '../common/styledComponents';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import LinkText from '../../../components/LinkText/LinkText';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const CheckBoxes = ({
  handleCheckBoxToggle,
  acceptedTerms,
  privacyPolicyUrl,
  termsAndConditionsUrl,
  clientText,
  emailOptIn,
  clientFurtherInfoText,
}) => (
  <>
    <EmailOptInCheckBox
      handleCheckBoxToggle={handleCheckBoxToggle}
      emailOptIn={emailOptIn}
      clientFurtherInfoText={clientFurtherInfoText}
    />
    <TermsCheckBox
      handleCheckBoxToggle={handleCheckBoxToggle}
      acceptedTerms={acceptedTerms}
      privacyPolicyUrl={privacyPolicyUrl}
      termsAndConditionsUrl={termsAndConditionsUrl}
      clientText={clientText}
    />
  </>
);

const TermsCheckBox = ({
  handleCheckBoxToggle,
  acceptedTerms,
  privacyPolicyUrl,
  termsAndConditionsUrl,
  clientText,
}) => {
  return (
    <CheckBox>
      <ImageBW
        onClick={() => handleCheckBoxToggle('acceptedTerms', acceptedTerms)}
        source={acceptedTerms ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
        ibw={require(asset`checkbox.ibw`)}
        style={{ minWidth: 22, height: 20 }}
        className="checkbox-image"
      />
      <CheckBoxText>
        {getText(TEXT_KEY.HAVE_READ_AND_ACCEPT)}
        {`${clientText} `}
        <LinkText onClick={() => window.open(privacyPolicyUrl, '_blank')}>{getText(TEXT_KEY.PRIVACY_POLICY)}</LinkText>
        {` ${getText(TEXT_KEY.AND)} `}
        <LinkText onClick={() => window.open(termsAndConditionsUrl, '_blank')}>
          {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}
        </LinkText>
      </CheckBoxText>
    </CheckBox>
  );
};

const EmailOptInCheckBox = ({ handleCheckBoxToggle, emailOptIn, clientFurtherInfoText }) => {
  return (
    <CheckBox>
      <ImageBW
        onClick={() => handleCheckBoxToggle('emailOptIn', emailOptIn)}
        source={emailOptIn ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
        ibw={require(asset`checkbox.ibw`)}
        style={{ minWidth: 22, height: 20 }}
        className="checkbox-image"
      />
      <CheckBoxText>{clientFurtherInfoText}</CheckBoxText>
    </CheckBox>
  );
};

export default CheckBoxes;

