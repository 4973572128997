import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postOfferDetailMutation, postPurchaseOfferMutation, postRewardCenterHistoryMutation, postRewardCenterMutation, postRewardOfferHistoryDetailsMutation, postRewardOfferHistorySendEmailMutation } from './queries';
export var rewardCenterApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postRewardCenter: builder.mutation(postRewardCenterMutation),
      postPurchaseOffer: builder.mutation(postPurchaseOfferMutation),
      postOfferDetail: builder.mutation(postOfferDetailMutation),
      postRewardCenterHistory: builder.mutation(postRewardCenterHistoryMutation),
      postRewardOfferHistoryDetails: builder.mutation(postRewardOfferHistoryDetailsMutation),
      postRewardOfferHistorySendEmail: builder.mutation(postRewardOfferHistorySendEmailMutation)
    };
  }
});
rewardCenterApi.enhanceEndpoints({
  addTagTypes: ['RewardCenter'],
  keepUnusedDataFor: 60
});
var usePostRewardCenterMutation = rewardCenterApi.usePostRewardCenterMutation,
  usePostPurchaseOfferMutation = rewardCenterApi.usePostPurchaseOfferMutation,
  usePostOfferDetailMutation = rewardCenterApi.usePostOfferDetailMutation,
  usePostRewardCenterHistoryMutation = rewardCenterApi.usePostRewardCenterHistoryMutation,
  usePostRewardOfferHistoryDetailsMutation = rewardCenterApi.usePostRewardOfferHistoryDetailsMutation,
  usePostRewardOfferHistorySendEmailMutation = rewardCenterApi.usePostRewardOfferHistorySendEmailMutation,
  postRewardCenter = rewardCenterApi.endpoints.postRewardCenter;
export { usePostRewardCenterMutation, usePostPurchaseOfferMutation, usePostOfferDetailMutation, usePostRewardCenterHistoryMutation, usePostRewardOfferHistoryDetailsMutation, usePostRewardOfferHistorySendEmailMutation, postRewardCenter };