var FormId = require("../../../components/Form/FormId").default;
var FormType = require("../../../components/Form/FormType").default;
var InputValidator = require("../../../components/Form/InputValidator").default;
module.exports = {
  header: {
    title: "",
    backButtonText: ""
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.SUBJECT,
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      maxChar: 150,
      inputStyle: true,
      containerStyle: {
        padding: '6px 0',
        backgroundColor: '#0a171d',
        borderBottom: 'none',
        borderRadius: 12
      }
    }, {
      id: FormId.BODY,
      label: "",
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      multiline: true,
      numberOfLines: 6,
      rows: 6,
      containerStyle: {
        marginTop: '2rem',
        backgroundColor: '#0a171d',
        borderBottom: 'none',
        borderRadius: 12
      },
      placeholder: 'Type your message here',
      inputStyle: true
    }],
    submitButtonText: "SEND"
  }]
};