import React from 'react';
import { ForgotPassword, FormBody, FormInput, ErrorText, Password, Peeker } from './../../styles';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { LOGIN_FIELDS } from 'shared/screens/Login/login-validations';
import { TextInputBootstrap } from '../../../../components/TextInput';
import PasswordInput from '../../../../components/PasswordInput';

function DefaultLayout({ values, errors, onChangeHandler, handleBlur, openForgotPassword }) {
  return (
    <FormBody>
      <FormInput className="form-input-group">
        <TextInputBootstrap
          placeholder={getText(TEXT_KEY.EMAIL)}
          autoCapitalize="none"
          value={values.email}
          onChange={e => onChangeHandler(LOGIN_FIELDS.EMAIL, e.target.value, true)}
          onBlur={handleBlur(LOGIN_FIELDS.EMAIL)}
        />
        {errors.email && <ErrorText>{errors.email}</ErrorText>}
      </FormInput>
      <Password>
        <FormInput className="form-input-group">
          <PasswordInput
            placeholder={getText(TEXT_KEY.PASSWORD)}
            value={values.password}
            onChange={e => onChangeHandler(LOGIN_FIELDS.PASSWORD, e.target.value)}
            onBlur={handleBlur(LOGIN_FIELDS.PASSWORD)}
            secureTextEntry={true}
            customClassName="transparentInput"
            customPeekerStyle={Peeker}
          />
          {errors.password && <ErrorText>{errors.password}</ErrorText>}
        </FormInput>
        <ForgotPassword onClick={openForgotPassword}>{getText(TEXT_KEY.FORGOT_PASSWORD)}</ForgotPassword>
      </Password>
    </FormBody>
  );
}

export default DefaultLayout;

