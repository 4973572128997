/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  LoyaltyPointsLogo,
  PriceWrapper,
  FreeItemContainer,
  FreeItemWrapper,
  ButtonWrapper,
  FreeTextWrapper,
  ItemPlusOffer,
  VIPAccessWrapper,
  ItemPrice,
  PlusLoyaltyText,
  Styles,
  DurationFriendlyFull,
} from './styledComponents';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { purchaseActionTriggers } from 'shared/constants/userActions';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';

const GREEN_BUTTON = generateRender({
  hri: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  admiral: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  admes: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  default: assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY),
  millelacs: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  jamul: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
  ladyluck: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
});

/** @typedef {import('shared/api/Constants').BoostPackageItem} BP */

/** @param {BP} item */
export default function makeVIPItem(item, index, buyHandle, freeHandle, hasVIPPass, unlimitedVipAccess, themeContext) {
  const headerImg = [
    assetSource(ASSET_KEY.VIP_HOUR_TILE_1),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_2),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_3),
  ];

  const logos = [
    assetSource(ASSET_KEY.VIP_HOUR_TILE_1STAR),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_2STARS),
    assetSource(ASSET_KEY.VIP_HOUR_TILE_3STARS),
  ];

  const actionButton = unlimitedVipAccess
    ? assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
    : item.is_free
      ? assetSource(ASSET_KEY.ORANGE_BUTTON)
      : GREEN_BUTTON;
  const buttonTextStroke = unlimitedVipAccess
    ? BUTTON_STROKES.DISABLED
    : item.is_free
      ? BUTTON_STROKES.TERTIARY
      : BUTTON_STROKES.GREEN;

  return (
    <ImageBW
      source={item.is_free ? assetSource(ASSET_KEY.VIP_HOUR_TILE_FREE) : headerImg[index]}
      ibw={require(asset`images/coin-store-assets/vip-tiles/vip-hour-tile.ibw`)}
      manualAssetScale={3}
      key={'vip-' + item.item_id}
      style={themeContext.VIPItem.VIPHourTile}
      className="vipHourTile"
      innerStyle={Styles.tileInnerStyle}
    >
      <img src={logos[index]} style={themeContext.VIPItem.LogoImage} />
      <DurationFriendlyFull>{item.duration_friendly_full}</DurationFriendlyFull>
      <VIPAccessWrapper>{`${getText(TEXT_KEY.VIP)} ${getText(TEXT_KEY.ACCESS)}`}</VIPAccessWrapper>
      {!item.is_free && (
        <>
          <ItemPlusOffer theme={themeContext.VIPItem.ItemPlusOffer}>
            <PlusLoyaltyText theme={themeContext.VIPItem.PlusLoyaltyText}>
              {getText(TEXT_KEY.PLUS_SYMBOL)}
            </PlusLoyaltyText>
            <LoyaltyPointsLogo
              theme={themeContext.VIPItem.LoyaltyPointsLogo}
              src={assetSource(ASSET_KEY.LOYALTY_ICON)}
            />
            <PlusLoyaltyText theme={themeContext.VIPItem.PlusLoyaltyText}>
              {item.bonus_loyalty_points || '0'}
            </PlusLoyaltyText>
          </ItemPlusOffer>
          <PriceWrapper>
            <ItemPrice>{`${getText(TEXT_KEY.CURRENCY_SIGN)}${item.price}`}</ItemPrice>
          </PriceWrapper>
        </>
      )}
      {item.is_free && (
        <>
          <FreeItemContainer>
            <FreeItemWrapper>
              <FreeTextWrapper>{getText(TEXT_KEY.FREE)}</FreeTextWrapper>
            </FreeItemWrapper>
          </FreeItemContainer>
        </>
      )}
      <ButtonWrapper>
        <Button
          imageSource={actionButton}
          imageStyle={Styles.buyButtonImage}
          containerStyle={Styles.buyButtonStyle}
          objectFit="contain"
          textStyle={Styles.buyButtonText}
          textStroke={buttonTextStroke}
          label={item.is_free ? getText(TEXT_KEY.GET) : getText(TEXT_KEY.BUY)}
          onClick={() =>
            unlimitedVipAccess
              ? hasVIPPass()
              : item.is_free
                ? freeHandle(item.item_id, 'vip_room_access')
                : buyHandle(
                    item.item_id,
                    item.name,
                    'vip_room_access',
                    item.price,
                    purchaseActionTriggers.STORE_VIP_ITEM
                  )
          }
        />
      </ButtonWrapper>
    </ImageBW>
  );
}

