import React, { useContext } from 'react';
import { Container, LogoAsset, DividerDiv, StoreLogoAsset, LinkAssetHolder } from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import SmartBanner from '../../../components/SmartBanner';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const DefaultStoreDownload = () => {
  const { StoreDownload } = useContext(ThemeContext);
  return (
    <div>
      <SmartBanner style={{ position: 'unset' }} />
      <Container theme={StoreDownload.Container}>
        <LogoAsset src={assetSource(ASSET_KEY.LOGO)} alt="logo" />
        <p>Experience the thrill of an exciting new gaming experience!</p>
        <DividerDiv />
        <LinkAssetHolder href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
          <StoreLogoAsset src={assetSource(ASSET_KEY.BADGE_ANDROID)} alt="" />
        </LinkAssetHolder>
      </Container>
    </div>
  );
};
export default DefaultStoreDownload;

